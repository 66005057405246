<template>
    <div>
        <v-row>
            <div
                class="d-flex mb-6 mt-3"
                v-for="(insight, index) of insights"
                :key="index"
            >
                <v-card
                    class="pt-5 px-2 ma-2"
                    min-width="250px"
                    max-width="250px"
                >
                    <v-card-text
                        class="d-flex my-0 py-0 mx-0 px-0"
                        width="100%"
                    >
                        <v-chip
                            class="mb-1 mt-0 mx-2 pt-0 white--text"
                            :color="insight.color"
                            label
                            :style="{ height: '50px' }"
                        >
                            <v-icon center>
                                {{ insight.icon }}
                            </v-icon>
                        </v-chip>
                        <div class="mb-1 mt-0 pt-0">
                            <div class="d-flex mr-0 pr-0 text-capitalize">
                                <span>{{ insight.title }}</span>
                            </div>
                            <p class="text-h6 black--text">
                                {{ insight.value }}
                            </p>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </v-row>
        <v-data-table
            :headers="scopeHeaders"
            :items="scopes"
            class="elevation-0 mb-3"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                    style="background-color: #eeeeee; display: flex; flex-wrap: wrap;"
                >
                    <v-col cols="9">
                        <h2 class="ml-4">SCOPES</h2>
                    </v-col>

                    <v-spacer />
                    <v-col cols="3" style="min-width: fit-content;">
                        <v-btn-toggle
                            :style="{ width: '100%' }"
                            v-if="!scopeInEdition"
                        >
                            <v-btn
                                @click.stop="showYears()"
                                rounded
                                small
                                :style="{ width: '50%' }"
                                >YEARS</v-btn
                            >
                            <v-btn
                                @click.stop="showMonths()"
                                rounded
                                small
                                :style="{ width: '50%' }"
                                >MONTHS</v-btn
                            >
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.scopeTotalPrice`]="{ item }">
                <div class="my-4">
                    <p v-if="!item.notEditable">
                        {{
                            formatCurrency(
                                project.currency,
                                item.scopeTotalPrice
                            )
                        }}
                    </p>
                    <p v-else>
                        {{ formatCurrency(project.currency, item.value) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.scopeCurrentBalanceToBill`]="{ item }">
                <div class="my-4">
                    <p>
                        {{
                            formatCurrency(
                                project.currency,
                                item.scopeCurrentBalanceToBill
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.baseBalanceToBill`]="{ item }">
                <div class="my-4">
                    <p>
                        {{
                            formatCurrency(
                                project.currency,
                                item.baseBalanceToBill
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actualVsForecasted`]="{ item }">
                <div class="my-4" v-if="!item.notEditable">
                    <p>{{ item.actualVsForecasted.toFixed(2) }} %</p>
                </div>
            </template>
            <template v-slot:[`item.invoicedThisMonth`]="{ item }">
                <div class="my-4">
                    <p class="d-flex justify-end">
                        {{
                            item.invoicedThisMonth
                                ? formatCurrency(
                                      project.currency,
                                      Number(item.invoicedThisMonth)
                                  )
                                : '-'
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.dateYear`]="{ item, header }">
                <p class="d-flex justify-end align-right my-1">
                    {{ getDateScopePercentage(item, header.year) }}
                    %
                </p>
                <p class="d-flex justify-end align-right mb-1">
                    {{
                        formatCurrency(
                            project.currency,
                            getScopeDateAmount(item, header.year)
                        )
                    }}
                </p>
            </template>
            <template v-slot:[`item.scopeForecastedPercentage`]="{ item }">
                <div class="my-4">
                    <p
                        v-if="!item.notEditable"
                        class="my-0 text-capitalize"
                        :class="{
                            'text-orange':
                                calculateScopeForecastedPercentage(item) < 100,
                            'text-green':
                                calculateScopeForecastedPercentage(item) == 100,
                            'text-red':
                                calculateScopeForecastedPercentage(item) > 100,
                        }"
                    >
                        {{ calculateScopeForecastedPercentage(item) || 0 }}%
                    </p>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item, header }">
                <v-col
                    v-if="
                        (item[header.month] &&
                            item[header.month].percentage > 0) ||
                            scopeInEdition == item.id
                    "
                >
                    <v-row class="d-flex justify-end align-right">
                        <p class="my-1">
                            {{ getDateScopePercentage(item, header.month) }}%
                        </p>
                        <v-tooltip
                            right
                            class="d-flex justify-end align-right ml-1"
                            v-if="
                                ((item[header.month] &&
                                    item[header.month].editable) ||
                                    !item[header.month]) &&
                                    scopeInEdition == item.id
                            "
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    icon
                                    class="d-flex justify-end align-right mt-0 ml-0"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="
                                        openEditScopeMonth(
                                            item[header.month],
                                            header.month
                                        )
                                    "
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Forecast</span>
                        </v-tooltip>
                    </v-row>
                    <v-row class="d-flex justify-end align-right">
                        <p class="mb-1">
                            {{
                                formatCurrency(
                                    project.currency,
                                    getScopeDateAmount(item, header.month)
                                )
                            }}
                        </p>
                    </v-row>
                </v-col>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <td
                    :class="['fixed-column', 'white-background']"
                    :style="{
                        backgroundColor: 'white',
                        width: '150px',
                        textAlign: 'center',
                    }"
                    v-if="item"
                >
                    <v-menu rounded offset-y>
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn
                                v-if="!item.notEditable"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                depressed
                                class="ml-2"
                                :disabled="
                                    scopeInEdition && item.id != scopeInEdition
                                "
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-if="
                                    item.id != scopeInEdition &&
                                        user.id == project.projectManager
                                "
                                @click.stop="openReplaceScopeForecast(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1">mdi-sync</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Replace Quote Forecast
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.id != scopeInEdition"
                                @click.stop="openHistory(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1">mdi-book</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Forecast History
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.id == scopeInEdition"
                                @click="openAddNewScopeMonth(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1"
                                        >mdi-plus-circle</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Add New Month
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="
                                    item.id == scopeInEdition &&
                                        scopeDiffConditions()
                                "
                                :disabled="
                                    calculateScopeForecastedPercentage(item) >
                                        100
                                "
                                @click="replaceScopeForecast()"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1"
                                        >mdi-content-save</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Update Forecast
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.id == scopeInEdition"
                                @click="closeScopeReplaceForecast()"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1"
                                        >mdi-close-thick</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Cancel Update
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </td>
            </template>
            <template v-slot:[`body.append`]>
                <tr :style="{ 'background-color': '#eeeeee' }">
                    <td class="font-weight-bold">TOTALS</td>
                    <td class="font-weight-bold" />
                    <!-- Total Price -->
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-right my-0">
                            {{
                                formatCurrency(project.currency, project.price)
                            }}
                        </p>
                    </td>
                    <!-- CURRENT B2B -->
                    <td class="font-weight-bold">
                        <p
                            class="d-flex justify-end align-right my-0"
                            :style="{ width: '100%' }"
                        >
                            {{
                                formatCurrency(
                                    project.currency,
                                    totalCurrentB2B || 0
                                )
                            }}
                        </p>
                    </td>
                    <!-- BASE B2B -->
                    <td class="font-weight-bold">
                        <p
                            class="d-flex d-flex justify-end align-right my-0"
                            :style="{ width: '100%' }"
                        >
                            {{
                                formatCurrency(
                                    project.currency,
                                    totalScopeBaseB2B || 0
                                )
                            }}
                        </p>
                    </td>
                    <td class="font-weight-bold"></td>
                    <!-- Invoiced in current month -->
                    <td class="font-weight-bold text-end">
                        {{ totalInvoicedPerDate() }}
                    </td>
                    <!-- Forecasted Percentage -->
                    <td class="font-weight-bold text-end">
                        {{ insights[3].value }}
                    </td>
                    <!-- Forecasts for month -->
                    <template v-if="viewType == 'month'">
                        <td
                            class="my-0"
                            v-for="(header, index) in scopeHeaders.slice(
                                8,
                                scopeHeaders.length - 1
                            )"
                            :key="index"
                        >
                            <p
                                class="d-flex justify-end align-right my-0"
                                v-if="viewType == 'month'"
                            >
                                {{
                                    parseCurrency(
                                        totalPerScopeDate(header.month)
                                    )[0]
                                }}
                            </p>
                            <p
                                class="d-flex justify-end align-right my-0"
                                v-if="viewType == 'month'"
                            >
                                {{
                                    parseCurrency(
                                        totalPerScopeDate(header.month)
                                    )[1]
                                }}
                            </p>
                        </td>
                    </template>
                    <template v-if="viewType == 'year'">
                        <td
                            class="my-0"
                            v-for="(header, index) in scopeHeaders.slice(
                                8,
                                scopeHeaders.length - 1
                            )"
                            :key="index"
                        >
                            <p
                                class="d-flex justify-end align-right my-0"
                                v-if="viewType == 'year'"
                            >
                                {{
                                    parseCurrency(
                                        totalPerScopeDate(header.text)
                                    )[0]
                                }}
                            </p>
                            <p
                                class="d-flex justify-end align-right my-0"
                                v-if="viewType == 'year'"
                            >
                                {{
                                    parseCurrency(
                                        totalPerScopeDate(header.text)
                                    )[1]
                                }}
                            </p>
                        </td>
                    </template>
                    <td :class="['fixed-column', 'gray-background']" />
                </tr>
            </template>
        </v-data-table>
        <!--ForecastHistory-->
        <v-dialog persistent max-width="2000px" v-model="historyView">
            <ForecastHistory
                v-if="historyView"
                :historyQuote="historyQuote"
                :project="project"
                :invoices="invoices"
                @closeDialog="closeHistoryView"
            />
        </v-dialog>
        <!-- TEST SCOFE FORECAST FORM -->
        <v-dialog persistent max-width="300px" v-model="newMonthScopeForm">
            <ForecastEntryForm
                v-if="newMonthScopeForm"
                :createDialog="createDialog"
                :originalEntry="originalEntry"
                :dates="dateToShow"
                :forecast="forecast"
                :quote="selectedScope"
                @addEntry="addEntry"
                @replaceEntry="replaceEntry"
                @closeDialog="closeDialog"
            />
        </v-dialog>
        <!--ForecastForm-->
        <v-dialog persistent max-width="300px" v-model="newMonthForm">
            <ForecastEntryForm
                v-if="newMonthForm"
                :createDialog="createDialog"
                :originalEntry="originalEntry"
                :dates="dateToShow"
                :forecast="forecast"
                :quote="selectedForecast"
                @addEntry="addEntry"
                @replaceEntry="replaceEntry"
                @closeDialog="newMonthForm = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff'

export default {
    name: 'B2BForecast',
    props: {
        projectId: {
            type: String,
            default: () => '',
        },
    },
    components: {
        ForecastHistory: () =>
            import('@/components/Forecast/ForecastHistory.vue'),
        ForecastEntryForm: () =>
            import('@/components/Forecast/ForecastEntryForm.vue'),
    },
    data: () => ({
        loading: true,
        companyId: JSON.parse(localStorage.getItem('company')),
        insights: [
            {
                value: 0,
                color: '#3498DB',
                iconColor: '#1A5276',
                icon: 'mdi-currency-usd',
                title: 'Project Price',
            },
            {
                value: 0,
                color: '#27658f',
                iconColor: '#1A5276',
                icon: 'mdi-invoice-text-check-outline',
                title: 'Project Billed',
            },
            {
                value: 0,
                color: '#19405c',
                iconColor: '#1A5276',
                icon: 'mdi-cash-clock',
                title: 'Balance To Bill',
            },
            {
                value: 0,
                color: '#0b1c28',
                iconColor: '#1A5276',
                icon: 'mdi-percent-outline',
                title: 'Forecasted Percentage',
            },
        ],
        quoteHeaders: [],
        months: [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUN',
            'JUL',
            'AUG',
            'SEP',
            'OCT',
            'NOV',
            'DEC',
        ],
        viewType: 'month',
        forecastDialog: false,
        createDialog: undefined,
        selectedForecast: {},
        dateToShow: [],
        forecast: [],
        quoteInEdition: undefined,
        newMonthForm: false,
        originalEntry: {},
        quotes: [],
        invoices: {},
        invoicedPerQuote: {},
        originalForecast: null,
        originalForecast2: null,
        currentDate: '',
        project: {},
        historyView: false,
        historyQuote: {},
        historyInvoices: null,
        allDates: [],
        user: {},
        originalScopeHeaders: [],
        scopes: [],
        editScopeForecast: false,
        newMonthScopeForm: false,
        scopeInEdition: undefined,
        selectedScopeForecast: {},
        selectedScope: {},
        invoicedPerScope: {},
        scopeHeaders: [],
        totalScopePrices: undefined,
        totalCurrentB2B: undefined,
        totalScopeBaseB2B: undefined,
        initialInvoiced: 0,
    }),
    computed: {
        scopeForecastDiff: function() {
            const forecastKey = Object.keys(this.forecast.scopes).find(
                key => this.forecast.scopes[key].id == this.scopeInEdition
            )

            const newForecast = this.forecast.scopes[forecastKey]

            if (newForecast) {
                return objDiff(this.selectedScopeForecast, newForecast)
            } else {
                return []
            }
        },
    },
    async mounted() {
        try {
            this.loading = true

            let currentDate = new Date()
            this.currentDate = `${currentDate.getFullYear()}-${String(
                currentDate.getMonth() + 1
            ).padStart(2, '0')}`
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.project = await API.getProject(this.projectId)
            this.forecast = this.project.forecast || []

            this.forecast = this.project.forecastV2 || { scopes: {} }

            await this.getQuotesData()
            await this.getScopeInvoices()
            await this.getScopesByQuote()
            await this.getScopeTotals()

            this.setHeaders()
            this.getInsights()
            this.showMonths()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        scopeDiffConditions() {
            return Object.keys(this.scopeForecastDiff).length > 0
        },
        parseCurrency(value) {
            if (value instanceof Promise) {
                value = value.value
            }
            if (value) {
                const parts = value.split(' - ')
                return parts.map(part => part.trim())
            }
            return
        },
        async getQuotesData() {
            try {
                this.quotes = await API.getProjectQuotes(this.project.id)
                this.quotes = this.quotes.map(quote => {
                    return {
                        costExchanges: quote.costExchanges,
                        currency: quote.currency,
                        exchange: quote.exchange,
                        id: quote.id,
                        name: quote.name,
                        number: quote.number,
                        totalCosts: quote.totalCosts,
                        createdOn: quote.createdOn,
                        updatedOn: quote.updatedOn,
                    }
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async getScopesByQuote() {
            // set scopes by quote
            let scopes = []
            let costs = await this.getForecastCosts()
            if (this.quotes.length > 0) {
                for (let quote of this.quotes) {
                    if (quote.id) {
                        quote.scopes = []
                        Object.values(costs).forEach(cost => {
                            if (quote.id == cost.quoteId) {
                                quote.scopes.push(cost)
                            }
                        })
                        if (quote.scopes) {
                            const filteredScopes = quote.scopes.filter(
                                scope => scope?.type == 'cost'
                            )
                            filteredScopes.forEach(scope => {
                                // invoiced per scope for past month, current month, year and total
                                let invoicedPerScope = this.invoicedPerScope
                                Object.values(invoicedPerScope).forEach(
                                    invoiced => {
                                        if (invoiced.id == scope.id) {
                                            scope.pastMonthInvoiced = Number(
                                                invoiced.pastMonths || 0
                                            )

                                            scope.invoicedThisMonth = Number(
                                                invoiced.thisMonth || 0
                                            )

                                            scope.totalInvoiced = Number(
                                                invoiced.total || 0
                                            )

                                            scope.invoicedThisYear = Number(
                                                invoiced.thisYear || 0
                                            )
                                        }
                                    }
                                )

                                // create scope object
                                let newScope = {
                                    id: scope.id,
                                    name: scope.reference,
                                    quoteName: quote.name,
                                    quote: quote,
                                    quoteCurrency: quote.currency,
                                    scopeTotalPrice: Number(scope.salePrice2),
                                    scopeCurrentBalanceToBill:
                                        Number(scope.salePrice2) -
                                        (scope.totalInvoiced || 0),
                                    baseBalanceToBill:
                                        Number(scope.salePrice2) -
                                        (scope.pastMonthInvoiced || 0),
                                    pastMonthInvoiced:
                                        scope.pastMonthInvoiced || 0,
                                    invoicedThisMonth:
                                        scope.invoicedThisMonth || 0,
                                    totalInvoiced: scope.totalInvoiced || 0,
                                    invoicedThisYear:
                                        scope.invoicedThisYear || 0,
                                }

                                // get existent forecast and set her data
                                let matchingForecast = this.forecast.scopes?.[
                                    scope.id
                                ]
                                let totalForecasted = 0
                                if (
                                    matchingForecast &&
                                    matchingForecast.dates
                                ) {
                                    Object.keys(matchingForecast.dates).forEach(
                                        dateKey => {
                                            if (
                                                Object.prototype.hasOwnProperty.call(
                                                    matchingForecast.dates,
                                                    dateKey
                                                )
                                            ) {
                                                const elm =
                                                    matchingForecast.dates[
                                                        dateKey
                                                    ]

                                                // add forecast months to scope
                                                if (!newScope[dateKey]) {
                                                    if (elm?.forecast) {
                                                        elm.forecast.editable = true
                                                        newScope[dateKey] =
                                                            elm.forecast
                                                    } else {
                                                        elm.editable = true
                                                        newScope[dateKey] = elm
                                                    }
                                                }

                                                // get current date
                                                let currentDate = moment(
                                                    new Date()
                                                ).format('YYYY-MM')
                                                // convert datekey in moment
                                                let dateKeyMoment = moment(
                                                    dateKey
                                                )

                                                // if datekey is valid, sum the forecast amount
                                                if (
                                                    dateKeyMoment.isSameOrAfter(
                                                        currentDate
                                                    )
                                                ) {
                                                    if (elm.forecast) {
                                                        // set total forecasted
                                                        totalForecasted += Number(
                                                            elm.forecast
                                                                .amount || 0
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    )
                                }
                                // set actual vs forecasted
                                if (totalForecasted === 0) {
                                    newScope.actualVsForecasted = 0
                                } else {
                                    newScope.actualVsForecasted =
                                        (Number(newScope.invoicedThisMonth) *
                                            100) /
                                        Math.abs(totalForecasted)
                                }

                                scopes.push(newScope)
                            })
                        }
                    }
                }
            }
            // add initial invoice as scope
            if (this.initialInvoiced > 0) {
                const initialScope = {
                    id: 'initialInvoice',
                    name: 'Initial Invoice',
                    quoteCurrency: '',
                    scopeTotalPrice: 0,
                    value: this.initialInvoiced || 0,
                    quoteName: '',
                    quote: [],
                    scopeCurrentBalanceToBill: 0,
                    baseBalanceToBill: 0,
                    pastMonthInvoiced: 0,
                    invoicedThisMonth: 0,
                    totalInvoiced: this.initialInvoiced || 0,
                    invoicedThisYear: 0,
                    actualVsForecasted: 0,
                    notEditable: true,
                }
                scopes.push(initialScope)
            }

            this.scopes = scopes
        },
        async getForecastCosts() {
            if (this.quotes.length > 0) {
                // Sort by the most oldest quote
                this.quotes.sort(
                    (a, b) =>
                        (a.createdOn?._seconds || a.createdOn?.seconds || 0) -
                        (b.createdOn?._seconds || b.createdOn?.seconds || 0)
                )

                const startDateSeconds =
                    this.quotes[0]?.createdOn?._seconds ||
                    this.quotes[0]?.createdOn?.seconds

                const startDate = moment
                    .unix(startDateSeconds)
                    .format('YYYY-MM-DD')

                const endDateSeconds =
                    this.quotes[this.quotes.length - 1]?.updatedOn?._seconds ||
                    this.quotes[this.quotes.length - 1]?.updatedOn?.seconds

                const endDate = moment.unix(endDateSeconds).format('YYYY-MM-DD')

                // get quotes ids
                let ids = this.quotes.map(quote => quote?.id).filter(id => id)

                const costs = await API.getForecastCosts(startDate, endDate, [
                    ids,
                ])
                return costs
            }
        },
        async getScopeInvoices() {
            let invoices = await API.getProjectInvoices(this.project.id)

            // get initial invoice
            invoices.forEach(invoice => {
                if (invoice.items['free']) {
                    invoice.items['free'].forEach(item => {
                        this.initialInvoiced += Number(item.currentInvoice || 0)
                    })
                }
            })

            // filter invoices
            invoices = invoices.filter(
                invoice =>
                    invoice.status != 'rejected' &&
                    invoice.status != 'inProgress' &&
                    invoice.billedOn
            )

            invoices.forEach(invoice => {
                invoice.date = this.formatDate(
                    invoice.billedOn
                        ? invoice.billedOn._seconds
                            ? invoice.billedOn._seconds
                            : invoice.billedOn.seconds
                        : 0
                )
            })
            this.invoicedPerScope = {}

            // get total invoiced per scope
            invoices.forEach(invoice => {
                if (invoice.items) {
                    Object.keys(invoice.items).forEach(quoteId => {
                        if (invoice.items[quoteId]) {
                            invoice.items[quoteId].forEach(item => {
                                const scopeId = item.costId

                                if (!this.invoicedPerScope[scopeId]) {
                                    this.invoicedPerScope[scopeId] = {
                                        id: scopeId,
                                        total: 0,
                                        thisMonth: 0,
                                        thisYear: 0,
                                        pastMonths: 0,
                                    }
                                }

                                const invoiced =
                                    item.currentInvoice -
                                    (item.creditQty || 0) *
                                        (item.pricePerUnit || 0)

                                this.invoicedPerScope[scopeId].total += invoiced
                            })
                        }
                    })
                }

                // get invoiced in past months per scope
                if (invoice.billedOn._seconds) {
                    const invoiceMonth = moment
                        .unix(invoice.billedOn._seconds)
                        .format('YYYY-MM')
                    const pastMonth = moment(this.currentDate, 'YYYY-MM-DD')
                        .subtract(1, 'month')
                        .format('YYYY-MM')
                    if (invoiceMonth <= pastMonth) {
                        if (invoice.items) {
                            Object.keys(invoice.items).forEach(quoteId => {
                                invoice.items[quoteId].forEach(item => {
                                    const scopeId = item.costId

                                    const invoicedInPastMonths =
                                        item.currentInvoice -
                                        (item.creditQty || 0) *
                                            (item.pricePerUnit || 0)

                                    if (this.invoicedPerScope[scopeId]) {
                                        this.invoicedPerScope[
                                            scopeId
                                        ].pastMonths += Number(
                                            invoicedInPastMonths || 0
                                        )
                                    } else {
                                        this.invoicedPerScope[scopeId] = {
                                            pastMonths: Number(
                                                invoicedInPastMonths || 0
                                            ),
                                        }
                                    }
                                })
                            })
                        }
                    }
                }

                // get invoiced this month per scope
                if (invoice.billedOn._seconds) {
                    const invoiceMonth = moment
                        .unix(invoice.billedOn._seconds)
                        .format('YYYY-MM')
                    if (invoiceMonth == this.currentDate) {
                        if (invoice.items) {
                            Object.keys(invoice.items).forEach(quoteId => {
                                invoice.items[quoteId].forEach(item => {
                                    const scopeId = item.costId

                                    const invoicedInMonth =
                                        item.currentInvoice -
                                        (item.creditQty || 0) *
                                            (item.pricePerUnit || 0)

                                    if (this.invoicedPerScope[scopeId]) {
                                        this.invoicedPerScope[
                                            scopeId
                                        ].thisMonth += Number(
                                            invoicedInMonth || 0
                                        )
                                    } else {
                                        this.invoicedPerScope[scopeId] = {
                                            thisMonth: Number(
                                                invoicedInMonth || 0
                                            ),
                                        }
                                    }
                                })
                            })
                        }
                    }
                }

                // get invoiced this year per scope
                if (invoice.billedOn._seconds) {
                    const invoiceYear = moment
                        .unix(invoice.billedOn._seconds)
                        .format('YYYY')
                    const currentYear = _.cloneDeep(
                        this.currentDate.split('-')[0]
                    )
                    if (invoiceYear == currentYear) {
                        if (invoice.items) {
                            Object.keys(invoice.items).forEach(quoteId => {
                                invoice.items[quoteId].forEach(item => {
                                    const scopeId = item.costId

                                    const invoicedInMonth =
                                        item.currentInvoice -
                                        (item.creditQty || 0) *
                                            (item.pricePerUnit || 0)

                                    if (this.invoicedPerScope[scopeId]) {
                                        this.invoicedPerScope[
                                            scopeId
                                        ].thisYear += Number(
                                            invoicedInMonth || 0
                                        )
                                    } else {
                                        this.invoicedPerScope[scopeId] = {
                                            thisYear: Number(
                                                invoicedInMonth || 0
                                            ),
                                        }
                                    }
                                })
                            })
                        }
                    }
                }
            })
            this.invoices = invoices
        },
        getScopeTotals() {
            if (this.scopes.length > 0) {
                this.totalScopePrices = this.scopes.reduce(
                    (accumulator, scope) =>
                        accumulator + (scope.scopeTotalPrice || 0),
                    0
                )
                // get total base balance to bill
                this.totalScopeBaseB2B = this.scopes.reduce(
                    (accumulator, scope) =>
                        accumulator + (scope.baseBalanceToBill || 0),
                    0
                )
                // get total current balance to bill
                this.totalCurrentB2B = this.scopes.reduce(
                    (accumulator, scope) =>
                        accumulator + (scope.scopeCurrentBalanceToBill || 0),
                    0
                )

                this.totalInvoiced = this.scopes.reduce(
                    (accumulator, scope) =>
                        accumulator + (scope.totalInvoiced || 0),
                    0
                )
            }
        },
        getDateScopePercentage(item, date) {
            if (date.includes('-')) {
                if (item[date] && item[date].amount && item.scopeTotalPrice) {
                    const amount = this.getScopeDateAmount(item, date)
                    const percentage = (
                        (Number(amount) * 100) /
                        item.scopeTotalPrice
                    ).toFixed(2)
                    return percentage
                }
            } else {
                const amount = this.getScopeDateAmount(item, date)
                const percentage = (
                    (Number(amount) * 100) /
                    item.scopeTotalPrice
                ).toFixed(2)
                return percentage
            }
            return '0.00'
        },
        getScopeDateAmount(item, date) {
            if (date.includes('-')) {
                if (item[date] && item[date].amount) {
                    return item[date].amount
                } else {
                    return 0
                }
            } else {
                let amount = 0
                // for every months in the current year
                Object.values(this.forecast.scopes).forEach(forecast => {
                    if (forecast.id == item.id) {
                        Object.entries(forecast.dates).forEach(
                            ([forecastDate, value]) => {
                                if (forecastDate.split('-')[0] == date) {
                                    amount += Number(value.forecast.amount)
                                }
                            }
                        )
                    }
                })
                return amount
            }
        },
        setHeaders() {
            const currentMonth = this.months[new Date().getMonth()]
            const currentYear = new Date().getFullYear()
            const invoicedHeaderText = `INVOICED IN ${currentMonth}`
            const invoicedHeaderTextYear = `INVOICED IN ${currentYear}`
            this.originalScopeHeaders = [
                {
                    text: 'SCOPE',
                    value: 'name',
                    align: 'end',
                    sortable: false,
                    class: 'gray-background',
                    width: 150,
                },
                {
                    text: 'QUOTE',
                    value: 'quoteName',
                    align: 'end',
                    sortable: false,
                    class: 'gray-background',
                    width: 150,
                },
                {
                    text: 'PRICE',
                    value: 'scopeTotalPrice',
                    align: 'end',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
                {
                    text: 'CURRENT BALANCE TO BILL',
                    value: 'scopeCurrentBalanceToBill',
                    align: 'end',
                    sortable: false,
                    class: 'gray-background',
                    width: 140,
                },
                {
                    text: 'BASE BALANCE TO BILL',
                    value: 'baseBalanceToBill',
                    align: 'end',
                    sortable: false,
                    class: 'gray-background',
                    width: 150,
                },
                {
                    text: 'ACTUAL VS FORECASTED',
                    value: 'actualVsForecasted',
                    align: 'end',
                    sortable: false,
                    class: 'gray-background',
                    width: 150,
                },
                {
                    text: invoicedHeaderText,
                    value: 'invoicedThisMonth',
                    align: 'end',
                    sortable: false,
                    class: 'gray-background',
                    width: 150,
                    type: 'month',
                },
                {
                    text: invoicedHeaderTextYear,
                    value: 'invoicedThisYear',
                    align: 'end',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                    type: 'year',
                },
                {
                    text: 'FORECASTED PERCENTAGE',
                    value: 'scopeForecastedPercentage',
                    align: 'end',
                    sortable: false,
                    class: 'gray-background',
                    width: 150,
                },
            ]

            this.calculateScopeUsedDates()

            let yearClasses = {}
            let toggleClass = true

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const monthIndex = Number(dateParts[1]) - 1
                const year = dateParts[0]
                const dateText = `${this.months[monthIndex]}-${year}`

                if (!yearClasses[year]) {
                    yearClasses[year] = toggleClass
                        ? 'dark-gray-background'
                        : 'gray-background'
                    toggleClass = !toggleClass
                }
                this.originalScopeHeaders.push({
                    text: dateText,
                    value: 'date',
                    align: 'end',
                    sortable: false,
                    width: 150,
                    type: 'month',
                    month: date,
                    class: yearClasses[year],
                })
            })

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const year = dateParts[0]
                const existent = this.originalScopeHeaders.find(
                    h => h.text == year
                )
                if (!existent) {
                    this.originalScopeHeaders.push({
                        text: year,
                        value: 'dateYear',
                        align: 'end',
                        sortable: false,
                        width: 150,
                        type: 'year',
                        class: yearClasses[year],
                        year,
                    })
                }
            })
            this.originalScopeHeaders.push({
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
                class: 'fixed-column gray-background',
            })
        },
        getInsights() {
            const project = this.project
            this.insights[0].value = this.formatCurrency(
                project.currency,
                project.price
            )
            this.insights[1].value = this.totalInvoiced
                ? this.formatCurrency(project.currency, project.totalInvoiced)
                : '-'
            this.insights[2].value = this.formatCurrency(
                project.currency,
                project.price - project.totalInvoiced
            )
            const totalPercentage = this.calculateScopeTotalPercentage()
            this.insights[3].value = totalPercentage + '%'
            if (totalPercentage == 100) {
                this.insights[3].color = '#2f9a6b'
            } else if (totalPercentage > 100) {
                this.insights[3].color = '#cb3c33'
            } else {
                this.insights[3].color = '#d86514'
            }
        },
        formatCurrency(currency, value) {
            if (currency && value) {
                const formattedValue = new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: currency,
                    maximumFractionDigits: 0,
                }).format(Number(value))
                if (Math.abs(Number(value || 0).toFixed(0)) == 0) {
                    return '-'
                }
                return formattedValue
            } else if (currency && !value) {
                return '-'
            }
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM')}`
        },
        closeHistoryView() {
            this.historyView = false
        },
        openReplaceScopeForecast(scope) {
            this.showMonths()
            this.scopeInEdition = scope.id
            this.calculateScopeForecastValues(scope)
            this.selectedScope = _.cloneDeep(scope)
        },
        calculateScopeUsedDates() {
            this.allDates = [this.currentDate]
            if (this.forecast) {
                Object.values(this.forecast.scopes).forEach(scope => {
                    if (scope.dates) {
                        Object.values(scope.dates).forEach(entry => {
                            if (entry?.forecast?.date) {
                                this.allDates.push(entry.forecast.date)
                            } else if (entry.date) {
                                this.allDates.push(entry.date)
                            }
                        })
                    }
                })
                this.dateToShow = [...new Set(this.allDates.sort())]
                const minDate = this.currentDate
                const maxDate = this.dateToShow[this.dateToShow.length - 1]
                this.dateToShow = this.monthsBetweenDates(
                    moment(minDate),
                    moment(maxDate)
                )
            }
        },
        calculateScopeForecastValues(scope) {
            // format dates to UTC-5 for every forecast
            Object.values(this.forecast.scopes).forEach(forecast => {
                if (forecast) {
                    if (forecast.createdOn) {
                        const createdOnTimestamp = this.getTimestamp2(
                            forecast.createdOn
                        )
                        forecast.createdOn = this.toUtc5(createdOnTimestamp)
                    }
                    if (forecast.updatedOn) {
                        const updatedOnTimestamp = this.getTimestamp2(
                            forecast.updatedOn
                        )
                        forecast.updatedOn = this.toUtc5(updatedOnTimestamp)
                    }
                }
            })

            const forecastKey = Object.keys(this.forecast.scopes).find(
                key => this.forecast.scopes[key].id == this.scopeInEdition
            )

            this.calculateScopeUsedDates()
            this.originalForecast2 = _.cloneDeep(this.forecast)
            this.originalScopes = _.cloneDeep(this.scopes)
            this.originalDatesToShow = _.cloneDeep(this.dateToShow)

            if (forecastKey) {
                this.forecast.scopes[forecastKey].balanceToBill =
                    scope.scopeCurrentBalanceToBill || 0

                if (this.forecast.scopes[forecastKey].dates) {
                    Object.values(
                        this.forecast.scopes[forecastKey].dates
                    ).forEach(entry => {
                        entry.forecast.editable = true
                        entry.forecast.percentage =
                            (
                                (Number(entry.forecast.amount || 0) * 100) /
                                scope.scopeTotalPrice
                            ).toFixed(2) || 0.0
                    })
                }
            } else {
                this.forecast.scopes[scope.id] = {
                    id: scope.id,
                    balanceToBill: scope.scopeTotalPrice,
                    dates: {},
                    createdOn: this.toUtc5(Date.now()),
                    updatedOn: this.toUtc5(Date.now()),
                }
            }
            this.selectedScopeForecast = this.forecast.scopes[scope.id]
        },
        closeDialog() {
            this.newMonthScopeForm = false
        },
        closeScopeReplaceForecast() {
            this.scopeInEdition = undefined
            this.selectedScope = undefined
            this.scopes = _.cloneDeep(this.originalScopes)
            this.dateToShow = _.cloneDeep(this.originalDatesToShow)
            this.forecast = _.cloneDeep(this.originalForecast2)
            this.setHeaders()
            this.getInsights()
        },
        async replaceScopeForecast() {
            try {
                this.loading = true
                this.selectedScopeForecast = this.forecast.scopes[
                    this.scopeInEdition
                ]
                this.scopeInEdition = undefined
                for (const key in this.forecast.scopes) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            this.forecast.scopes,
                            key
                        )
                    ) {
                        const scope = this.forecast.scopes[key]
                        if (scope.dates) {
                            for (const dateKey in scope.dates) {
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        scope.dates,
                                        dateKey
                                    )
                                ) {
                                    const entry = scope.dates[dateKey]
                                    delete entry.editable
                                }
                            }
                        }
                    }
                }
                const response = await API.replaceForecast({
                    projectId: this.project.id,
                    forecast: this.forecast,
                })

                this.forecast = response.data.project
                this.getInsights()
                await this.getScopesByQuote()
                await this.getScopeTotals()
                this.calculateScopeUsedDates()
                await this.setUpdate()
                this.loading = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async setUpdate() {
            this.$emit('setUpdate', this.forecast, this.project.id)
        },
        showMonths() {
            this.scopeHeaders = this.originalScopeHeaders.filter(
                h => h.type != 'year'
            )
            this.viewType = 'month'
        },
        showYears() {
            this.scopeHeaders = this.originalScopeHeaders.filter(
                h => h.type != 'month'
            )
            this.viewType = 'year'
        },
        monthsBetweenDates(startDate, endDate) {
            let datesObject = []
            let currentDay = startDate
            let dates = []
            while (currentDay.isSameOrBefore(endDate)) {
                dates.push(currentDay.format('YYYY-MM'))
                datesObject[currentDay.format('YYYY-MM')] = 0
                currentDay.add(1, 'month')
            }
            return dates
        },
        calculateScopeTotalPercentage() {
            let totalForecasted = 0
            let totalInvoiced = 0
            let totalPrice = 0
            Object.values(this.scopes).forEach(scope => {
                totalPrice += Number(scope.scopeTotalPrice || 0)
                totalInvoiced += Number(scope.totalInvoiced || 0)
                const dateKeys = Object.keys(scope).filter(key =>
                    /^\d{4}-\d{2}$/.test(key)
                )

                dateKeys.forEach(dateKey => {
                    if (moment(dateKey).isSameOrAfter(this.currentDate)) {
                        totalForecasted += Number(scope[dateKey].amount) || 0
                    }
                })
            })

            let invoicedPercentage = (totalInvoiced * 100) / totalPrice
            let projectedPercentage = (totalForecasted * 100) / totalPrice

            return (invoicedPercentage + projectedPercentage).toFixed(2)
        },
        calculateScopeForecastedPercentage(scope) {
            let totalForecasted = 0
            const dateKeys = Object.keys(scope).filter(key =>
                /^\d{4}-\d{2}$/.test(key)
            )

            dateKeys.forEach(dateKey => {
                if (moment(dateKey).isSameOrAfter(this.currentDate)) {
                    totalForecasted += Number(scope[dateKey].amount) || 0
                }
            })

            let invoicedPercentage =
                (scope.totalInvoiced * 100) / scope.scopeTotalPrice
            let projectedPercentage =
                (totalForecasted * 100) / scope.scopeTotalPrice

            return Number(
                invoicedPercentage + projectedPercentage || 0
            ).toFixed(2)
        },
        totalPerScopeDate(date) {
            if (!date) return '0.00% - $0.00'

            let resultPerDate = 0
            Object.values(this.scopes).forEach(scope => {
                const dateKeys = Object.keys(scope).filter(key =>
                    /^\d{4}-\d{2}$/.test(key)
                )
                dateKeys.forEach(dateKey => {
                    if (date.includes('-')) {
                        if (dateKey == date && scope[dateKey].amount) {
                            resultPerDate += Number(scope[dateKey].amount) || 0
                        }
                    } else {
                        if (dateKey.includes(date) && scope[dateKey].amount) {
                            resultPerDate += Number(scope[dateKey].amount) || 0
                        }
                    }
                })
            })
            const amount = this.formatCurrency(
                this.project.currency,
                resultPerDate.toFixed(2)
            )
            const percentage = (resultPerDate * 100) / this.project.price || 0.0
            return percentage.toFixed(2) + '%' + ' - ' + amount
        },
        totalInvoicedPerDate() {
            let resultPerDate = 0
            Object.values(this.scopes).forEach(scope => {
                if (this.viewType == 'month') {
                    if (scope.invoicedThisMonth) {
                        resultPerDate += scope.invoicedThisMonth
                    }
                } else if (this.viewType == 'year') {
                    if (scope.invoicedThisYear) {
                        resultPerDate += scope.invoicedThisYear
                    }
                }
            })

            const amount = this.formatCurrency(
                this.project.currency,
                resultPerDate.toFixed(2)
            )
            return amount
        },
        async totalInitialForecast() {
            const resultInitForecast = await this.sumNewAmounts()
            const amount = this.formatCurrency(
                this.project.currency,
                resultInitForecast.toFixed(2)
            )
            const percentage =
                (resultInitForecast * 100) / this.totalScopeBaseB2B || 0.0
            return percentage.toFixed(2) + '%' + ' - ' + amount
        },
        async sumNewAmounts() {
            let total = 0
            Object.values(this.forecast.scopes).forEach(scope => {
                Object.values(scope.dates).forEach(date => {
                    if (date.forecast.date) {
                        let forecastDate = moment(date.forecast.date)
                        if (forecastDate.isSameOrAfter(this.currentDate)) {
                            total += Number(date.forecast.amount)
                        }
                    }
                })
            })
            return total
        },
        openAddNewScopeMonth() {
            this.createDialog = true
            this.originalEntry = {}
            this.newMonthScopeForm = true
        },
        openEditScopeMonth(entry, date) {
            this.originalEntry = entry
                ? entry
                : { date, amount: 0, percentage: 0 }
            this.createDialog = false
            this.newMonthScopeForm = true
        },
        async addEntry(newEntry) {
            try {
                this.selectedScopeForecast = _.cloneDeep(
                    this.forecast.scopes[this.scopeInEdition]
                )
                this.forecast.scopes = this.forecast.scopes || {}
                this.forecast.scopes[this.scopeInEdition] = this.forecast
                    .scopes[this.scopeInEdition] || { dates: {} }

                const scope = this.forecast.scopes[this.scopeInEdition]

                scope.dates = scope.dates || {}

                if (!scope.dates[newEntry.date]) {
                    scope.dates[newEntry.date] = {
                        date: newEntry.date,
                        percentage: newEntry.percentage,
                        amount: newEntry.amount || 0,
                        updatedOn: this.toUtc5(Date.now()),
                        editable: true,
                    }
                }
                this.setHeaders()
                this.showMonths()
                await this.getScopesByQuote()
                await this.getScopeTotals()
                this.calculateScopeUsedDates()
                this.getInsights()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async replaceEntry(newEntry) {
            this.selectedScopeForecast = _.cloneDeep(
                this.forecast.scopes[this.scopeInEdition]
            )
            const forecastKey = Object.values(this.forecast.scopes).find(
                forecast => forecast.id == this.scopeInEdition
            )

            if (forecastKey) {
                this.forecast.scopes[forecastKey.id].dates[newEntry.date] = {
                    forecast: {
                        date: newEntry.date,
                        percentage: newEntry.percentage,
                        amount: newEntry.amount || 0,
                        updatedOn: this.toUtc5(Date.now()),
                        editable: true,
                    },
                    reference:
                        this.forecast.scopes[forecastKey.id].dates[
                            newEntry.date
                        ]?.reference || 0,
                }
            }
            this.setHeaders()
            this.showMonths()
            await this.getScopesByQuote()
            await this.getScopeTotals()
            this.calculateScopeUsedDates()
            this.getInsights()
        },
        openHistory(scope) {
            this.historyQuote = scope
            this.historyView = true
        },
        getTimestamp2(obj) {
            if (obj._seconds && obj._nanoseconds) {
                return obj._seconds * 1000 + obj._nanoseconds / 1e6
            } else if (obj.seconds && obj.nanoseconds) {
                return obj.seconds * 1000 + obj.nanoseconds / 1e6
            }
            return 0
        },
        toUtc5(timestamp) {
            const dateInUTC5 = new Date(timestamp - 5 * 60 * 60 * 1000)
            return dateInUTC5
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow-y: scroll;
}

.text-orange {
    color: orange;
    font-weight: bold;
}

.text-green {
    color: green;
    font-weight: bold;
}

.text-red {
    color: red;
    font-weight: bold;
}

.fixed-column {
    position: sticky;
    right: 0;
    z-index: 1;
}

.gray-background {
    background-color: #eee;
}

.white-background {
    background-color: white;
}

.dark-gray-background {
    background-color: #c3c3c3;
}

.v-data-table-header th {
    background-color: #eeeeee !important;
}

.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}

.v-data-table__row {
    height: 64px;
}

.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}

.v-data-table__wrapper {
    max-height: 65vh;
    overflow-y: auto;
}

thead {
    position: sticky;
    z-index: 10;
}
</style>
